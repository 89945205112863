import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import OverviewCard from './OverviewCard'

import ethConvert from '../../../function/EtherNumberConvert'

const useStyles = makeStyles(() => ({
    textWhite: {
        color: 'white',
    },
}))

const EstimatePriceResultOverview = ({
    historyPriceTimestamp,
    beforeStartDateEthUSDPrice,
    currentEthUSDPrice,
    totalClearanceFeeEth,
    searchDays,
    canClearanceDay,
    avgClearanceFeeEth,
    isEnableSavingMode,
    savedClearanceFeeEth,
}) => {
    const classes = useStyles()

    const converEthToUSD = (eth, usd) => {
        return (usd * eth).toFixed(2)
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="top">
            <Grid container>
                <Grid item sm={4}>
                    <Box mr={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>以太幣(ETH)為單位估算</li>
                        </Typography>
                        <OverviewCard
                            totalPrice={ethConvert(totalClearanceFeeEth)}
                            unableClearanceDay={searchDays - canClearanceDay}
                            canClearanceDay={canClearanceDay}
                            avgPrice={ethConvert(avgClearanceFeeEth)}
                            isEnableSavingMode={isEnableSavingMode}
                            savedPrice={ethConvert(savedClearanceFeeEth)}
                            unit="ETH"
                        />
                    </Box>
                </Grid>
                <Grid item sm={4}>
                    <Box ml={2} mr={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>
                                以{moment(historyPriceTimestamp).format('YYYY/MM/DD')}幣值估算(1ETH:
                                {beforeStartDateEthUSDPrice}USD)
                            </li>
                        </Typography>
                        <OverviewCard
                            totalPrice={converEthToUSD(ethConvert(totalClearanceFeeEth), beforeStartDateEthUSDPrice)}
                            unableClearanceDay={searchDays - canClearanceDay}
                            canClearanceDay={canClearanceDay}
                            avgPrice={converEthToUSD(ethConvert(avgClearanceFeeEth), beforeStartDateEthUSDPrice)}
                            isEnableSavingMode={isEnableSavingMode}
                            savedPrice={converEthToUSD(ethConvert(savedClearanceFeeEth), beforeStartDateEthUSDPrice)}
                            unit="USD"
                        />
                    </Box>
                </Grid>
                <Grid item sm={4}>
                    <Box ml={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>以現在幣值估算(1ETH:{currentEthUSDPrice}USD)</li>
                        </Typography>
                        <OverviewCard
                            totalPrice={converEthToUSD(ethConvert(totalClearanceFeeEth), currentEthUSDPrice)}
                            unableClearanceDay={searchDays - canClearanceDay}
                            canClearanceDay={canClearanceDay}
                            avgPrice={converEthToUSD(ethConvert(avgClearanceFeeEth), currentEthUSDPrice)}
                            isEnableSavingMode={isEnableSavingMode}
                            savedPrice={converEthToUSD(ethConvert(savedClearanceFeeEth), currentEthUSDPrice)}
                            unit="USD"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

EstimatePriceResultOverview.propTypes = {
    historyPriceTimestamp: PropTypes.number.isRequired,
    beforeStartDateEthUSDPrice: PropTypes.number.isRequired,
    currentEthUSDPrice: PropTypes.number.isRequired,
    totalClearanceFeeEth: PropTypes.string.isRequired,
    searchDays: PropTypes.number.isRequired,
    canClearanceDay: PropTypes.number.isRequired,
    avgClearanceFeeEth: PropTypes.string.isRequired,
    isEnableSavingMode: PropTypes.bool.isRequired,
    savedClearanceFeeEth: PropTypes.string.isRequired,
}
export default EstimatePriceResultOverview
