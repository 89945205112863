import React from 'react'
import PropTypes from 'prop-types'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

const useStyles = makeStyles(() => ({
    cardContent: {
        flexShrink: 0,
        overflow: 'auto',
        width: 'auto',
    },
    tableCell: {
        overflow: 'auto',
        width: 'auto',
        borderBottom: 'none',
    },
    tableRow: {
        borderBottomStyle: 'solid',
        borderBottomColor: '#E8E8E8',
        borderBottomWidth: 1,
    },
}))

const OverviewCard = ({
    totalPrice,
    unableClearanceDay,
    canClearanceDay,
    avgPrice,
    isEnableSavingMode,
    savedPrice,
    unit,
}) => {
    const classes = useStyles()

    const renderIsEnableSavingMode = () => {
        if (isEnableSavingMode === true) {
            return '是'
        } else {
            return '否'
        }
    }

    const renderSavedPrice = () => {
        if (!isEnableSavingMode) {
            return '未設定節費模式'
        } else {
            if (canClearanceDay > 0) {
                return savedPrice + '(' + unit + ')'
            } else {
                return '設定值無法上鏈'
            }
        }
    }

    return (
        <div>
            <Box mt={1}>
                <TableContainer component={Card} className={classes.cardContent}>
                    <Table>
                        <TableBody>
                            <TableRow id="total-cl-price" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">總上鏈費用</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">
                                        {canClearanceDay > 0 ? totalPrice + '(' + unit + ')' : '設定值無法上鏈'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow id="success-cl-days" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">可上鏈天數</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">{canClearanceDay}天</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow id="fail-cl-days" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">無法上鏈天數</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">{unableClearanceDay}天</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow id="avg-cl-price" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">平均每日上鏈費用</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">
                                        {canClearanceDay > 0 ? avgPrice + '(' + unit + ')' : '設定值無法上鏈'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow id="set-save-mode" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">是否設定節費模式</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">{renderIsEnableSavingMode()}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow id="saved-price" className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">設定節費模式省下費用</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">{renderSavedPrice()}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

OverviewCard.propTypes = {
    totalPrice: PropTypes.string.isRequired,
    unableClearanceDay: PropTypes.number.isRequired,
    canClearanceDay: PropTypes.number.isRequired,
    avgPrice: PropTypes.string.isRequired,
    isEnableSavingMode: PropTypes.bool.isRequired,
    savedPrice: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
}

export default OverviewCard
