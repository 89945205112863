import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Modal, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import InfoIcon from '../../../img/infoButton.svg'
import GasPriceInstructionModal from '../modal/GasPriceInstructionModal'
import { getScheduleTimeTextWithTz } from '../../../function/ScheduleTimeConvert'
import { getUTCTimeZoneText } from '../../../function/DateTimeUtil'

const useStyles = makeStyles(() => ({
    subTitleText: {
        color: 'white',
        fontSize: '18px',
    },
    iconButton: {
        padding: '5px',
    },
}))

const RecommendSettingResult = ({ recommendSchedulerTime, recommendGasPriceLimit }) => {
    const classes = useStyles()
    const [gasPriceModalOpen, setGasPriceModalOpen] = useState(false)

    const renderScheduleTime = () => {
        let timeZoneText = '(' + getUTCTimeZoneText() + ')'
        let timeText = getScheduleTimeTextWithTz(recommendSchedulerTime)
        return '建議設定排程上鏈時間: ' + timeText + timeZoneText
    }

    const renderGasPriceLimit = () => {
        return '建議設定Gas Price上限: ' + recommendGasPriceLimit + 'Gwei'
    }

    const handleGasPriceInfoOpen = () => {
        setGasPriceModalOpen(true)
    }

    const handleGasPriceModalClose = () => {
        setGasPriceModalOpen(false)
    }

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography className={classes.subTitleText}>推薦設定</Typography>
                <IconButton className={classes.iconButton} onClick={handleGasPriceInfoOpen}>
                    <img alt="InfoIcon" src={InfoIcon} height="20px" />
                </IconButton>
                <Modal open={gasPriceModalOpen} onClose={handleGasPriceModalClose}>
                    <Box>
                        <GasPriceInstructionModal close={handleGasPriceModalClose} />
                    </Box>
                </Modal>
            </Box>
            <Alert severity="info" style={{ alignItems: 'center', fontSize: 'medium', height: '70px' }}>
                {renderScheduleTime()}
                <br />
                {renderGasPriceLimit()}
            </Alert>
        </>
    )
}

RecommendSettingResult.propTypes = {
    recommendSchedulerTime: PropTypes.number.isRequired,
    recommendGasPriceLimit: PropTypes.number.isRequired,
}
export default RecommendSettingResult
