import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ResultPriceCard from './ResultPriceCard'

import ethConvert from '../../../function/EtherNumberConvert'

const useStyles = makeStyles(() => ({
    subTitleText: {
        color: 'white',
        fontSize: '18px',
    },
    textWhite: {
        color: 'white',
    },
}))

const OverallEstimatePrice = ({
    historyPriceTimestamp,
    beforeStartDateEthUSDPrice,
    currentEthUSDPrice,
    totalClearanceFeeEth,
    clearanceDay,
    avgClearanceFeeEth,
    unlimitedClearanceFeeEth,
    savedClearanceFeeEth,
}) => {
    const classes = useStyles()

    const converEthToUSD = (eth, usd) => {
        return (usd * eth).toFixed(2)
    }

    return (
        <>
            <Box mt={2}>
                <Typography className={classes.subTitleText}>估算價格</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="top">
                <Grid sm={4}>
                    <Box mr={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>以太幣(ETH)為單位估算</li>
                        </Typography>
                        <ResultPriceCard
                            totalClearanceFee={ethConvert(totalClearanceFeeEth)}
                            clearanceDay={clearanceDay}
                            avgClearanceFee={ethConvert(avgClearanceFeeEth)}
                            unlimitedClearanceFee={ethConvert(unlimitedClearanceFeeEth)}
                            savedClearanceFee={ethConvert(savedClearanceFeeEth)}
                            unit="ETH"
                        />
                    </Box>
                </Grid>
                <Grid sm={4}>
                    <Box ml={2} mr={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>
                                以{moment(historyPriceTimestamp).format('YYYY/MM/DD')}幣值估算(1ETH:
                                {beforeStartDateEthUSDPrice}USD)
                            </li>
                        </Typography>
                        <ResultPriceCard
                            totalClearanceFee={converEthToUSD(
                                ethConvert(totalClearanceFeeEth),
                                beforeStartDateEthUSDPrice,
                            )}
                            clearanceDay={clearanceDay}
                            avgClearanceFee={converEthToUSD(ethConvert(avgClearanceFeeEth), beforeStartDateEthUSDPrice)}
                            unlimitedClearanceFee={converEthToUSD(
                                ethConvert(unlimitedClearanceFeeEth),
                                beforeStartDateEthUSDPrice,
                            )}
                            savedClearanceFee={converEthToUSD(
                                ethConvert(savedClearanceFeeEth),
                                beforeStartDateEthUSDPrice,
                            )}
                            unit="USD"
                        />
                    </Box>
                </Grid>
                <Grid sm={4}>
                    <Box ml={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            <li>以現在幣值估算(1ETH:{currentEthUSDPrice}USD)</li>
                        </Typography>
                        <ResultPriceCard
                            totalClearanceFee={converEthToUSD(ethConvert(totalClearanceFeeEth), currentEthUSDPrice)}
                            clearanceDay={clearanceDay}
                            avgClearanceFee={converEthToUSD(ethConvert(avgClearanceFeeEth), currentEthUSDPrice)}
                            unlimitedClearanceFee={converEthToUSD(
                                ethConvert(unlimitedClearanceFeeEth),
                                currentEthUSDPrice,
                            )}
                            savedClearanceFee={converEthToUSD(ethConvert(savedClearanceFeeEth), currentEthUSDPrice)}
                            unit="USD"
                        />
                    </Box>
                </Grid>
            </Box>
        </>
    )
}

OverallEstimatePrice.propTypes = {
    historyPriceTimestamp: PropTypes.number.isRequired,
    beforeStartDateEthUSDPrice: PropTypes.number.isRequired,
    currentEthUSDPrice: PropTypes.number.isRequired,
    totalClearanceFeeEth: PropTypes.string.isRequired,
    clearanceDay: PropTypes.number.isRequired,
    avgClearanceFeeEth: PropTypes.string.isRequired,
    unlimitedClearanceFeeEth: PropTypes.string.isRequired,
    savedClearanceFeeEth: PropTypes.string.isRequired,
}

export default OverallEstimatePrice
