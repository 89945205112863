import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import {
    Box,
    Button,
    FilledInput,
    FormControl,
    FormControlLabel,
    IconButton,
    makeStyles,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import GasPriceInstructionModal from '../modal/GasPriceInstructionModal'
import InfoIcon from '../../../img/infoButton.svg'
import { scheduleMenuItem } from '../../../function/ScheduleTimeConvert'
import estimateServerApi from '../../../action/EstimateServerApi'

const useStyles = makeStyles(() => ({
    headerCell: {
        color: 'white',
        borderBottom: 'none',
        fontSize: '1rem',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    textWhite: {
        color: 'white',
    },
    dateTimePicker: {
        backgroundColor: 'white',
        borderRadius: '0.35rem',
        '& .MuiInputBase-input': {
            padding: '12px 12px 10px',
            color: 'grey',
            width: '120px',
        },
    },
    bodyCell: {
        paddingLeft: 0,
        paddingTop: 0,
        borderBottom: 'none',
        fontSize: '1rem',
    },
    formControlLabelRoot: {
        color: 'white',
    },
    radio: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
    },

    checked: {},
    textField: {
        width: '70%',
        backgroundColor: 'white',
        borderRadius: '0.35rem',
    },
    textFieldPadding: {
        backgroundColor: '#fff',
        borderRadius: '0.35rem',
        '& .MuiFilledInput-input': {
            padding: '12px 12px 10px',
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    iconButton: {
        padding: '5px',
    },
    button: {
        backgroundColor: '#4E8BDC',
        color: 'white',
    },
}))

const SearchRuleInput = ({ onRuleSubmit }) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [gasPriceModalOpen, setGasPriceModalOpen] = useState(false)

    // date picker
    const defaultMinTS = new Date('2020-01-01').getTime()
    const defaultMaxTS = new Date().getTime()
    const [minDate, setMinDate] = useState(moment(defaultMinTS))
    const [maxDate, setMaxDate] = useState(moment(defaultMaxTS).subtract(1, 'week'))
    const [fromDate, setFromDate] = useState(minDate)
    const [toDate, setToDate] = useState(maxDate)

    // search rule param
    const [scheduleTime, setScheduleTime] = useState(0)
    const [saveMode, setSaveMode] = useState(true)
    const [gasPriceLimit, setGasPriceLimit] = useState('')

    // initialize time range
    useEffect(() => {
        estimateServerApi
            .get('/data/duration')
            .then((res) => {
                if (res.data.status === 'ok') {
                    setMinDate(moment(res.data.fromTimestamp))
                    setMaxDate(moment(res.data.toTimestamp))
                    setToDate(moment(res.data.toTimestamp))
                    setFromDate(moment(res.data.toTimestamp).subtract(1, 'week'))
                } else {
                    enqueueSnackbar('查詢資料時間區間失敗，請稍後再試或詢問開發人員', {
                        variant: 'error',
                    })
                }
            })
            .catch(() => {
                enqueueSnackbar('查詢資料時間區間失敗，請稍後再試或詢問開發人員', {
                    variant: 'error',
                })
            })
    }, [])

    const onFromDateChange = (date) => {
        if (toDate < date) {
            enqueueSnackbar('結束時間不能早於起始時間', {
                variant: 'warning',
            })
        } else {
            setFromDate(date)
        }
    }

    const onToDateChange = (date) => {
        if (date < fromDate) {
            enqueueSnackbar('結束時間不能早於起始時間', {
                variant: 'warning',
            })
        } else {
            setToDate(date)
        }
    }

    const handleScheduleSelectorChange = (event) => {
        setScheduleTime(event.target.value)
    }

    const handleSaveModeChange = (event) => {
        if (event.target.value.includes('true')) {
            setSaveMode(true)
        } else {
            setSaveMode(false)
        }
    }

    const handleGasPriceLimitChange = (event) => {
        if (event.target.value.length === 0) {
            setGasPriceLimit('')
        } else if (/^[0-9]$/.test(event.target.value.slice(-1))) {
            setGasPriceLimit(event.target.value)
        } else {
            return false
        }
    }

    const handleGasPriceInfoOpen = () => {
        setGasPriceModalOpen(true)
    }

    const handleGasPriceModalClose = () => {
        setGasPriceModalOpen(false)
    }

    const handleSubmitClick = () => {
        onRuleSubmit(fromDate, toDate, scheduleTime, saveMode, Number(gasPriceLimit))
    }

    return (
        <Box>
            <Box mb={1} display="flex" flexDirection="row" alignItems="flex-end">
                <Box>
                    <Typography className={classes.textWhite} variant="body1">
                        起始日期
                    </Typography>
                    <KeyboardDatePicker
                        className={classes.dateTimePicker}
                        disableToolbar
                        variant="inline"
                        margin="none"
                        format="YYYY/MM/DD"
                        id="date-picker-start-date"
                        value={fromDate}
                        onChange={onFromDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </Box>
                <Box ml={2}>
                    <Typography className={classes.textWhite} variant="body1">
                        結束日期
                    </Typography>
                    <KeyboardDatePicker
                        className={classes.dateTimePicker}
                        disableToolbar
                        variant="inline"
                        margin="none"
                        format="YYYY/MM/DD"
                        id="date-picker-end-date"
                        value={toDate}
                        onChange={onToDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </Box>
            </Box>
            <Box mb={1} display="flex" flexDirection="row">
                <Box>
                    <Box marginTop="3px">
                        <Typography className={classes.textWhite} variant="body1" component="p">
                            選擇排程時段
                        </Typography>
                    </Box>
                    <FormControl variant="outlined" className={classes.formControl} margin="dense">
                        <Select
                            labelId="schedule-time-selector-label"
                            id="schedule-time-selector"
                            value={scheduleTime}
                            onChange={handleScheduleSelectorChange}
                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                            style={{ backgroundColor: 'white' }}
                        >
                            {scheduleMenuItem.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={2}>
                    <Box marginTop="3px">
                        <Typography className={classes.textWhite} variant="body1" component="p">
                            是否開啟節費模式
                        </Typography>
                    </Box>
                    <Box marginLeft="3px" marginTop="3px">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="role" name="role" value={saveMode} onChange={handleSaveModeChange}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <FormControlLabel
                                        classes={{ root: classes.formControlLabelRoot }}
                                        value={true}
                                        control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                                        label="是"
                                    />
                                    <FormControlLabel
                                        classes={{ root: classes.formControlLabelRoot }}
                                        value={false}
                                        control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                                        label="否"
                                    />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                {saveMode && (
                    <Box ml={2}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.textWhite}>開啟節費模式欲設定之Gas Price上限</Typography>
                            <IconButton className={classes.iconButton} onClick={handleGasPriceInfoOpen}>
                                <img alt="InfoIcon" src={InfoIcon} height="20px" />
                            </IconButton>
                        </Box>
                        <Modal open={gasPriceModalOpen} onClose={handleGasPriceModalClose}>
                            <Box>
                                <GasPriceInstructionModal close={handleGasPriceModalClose} />
                            </Box>
                        </Modal>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <FormControl className={classes.textField} variant="filled" fullWidth>
                                <FilledInput
                                    id="gas-price-limit-input"
                                    className={classes.textFieldPadding}
                                    value={gasPriceLimit}
                                    onChange={handleGasPriceLimitChange}
                                />
                            </FormControl>
                            <Box ml={1}>
                                <Typography className={classes.textWhite}>Gwei</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box ml={2} marginTop="32px">
                    <Button
                        id="submit-rule"
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitClick}
                    >
                        確認
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

SearchRuleInput.propTypes = {
    onRuleSubmit: PropTypes.func.isRequired,
}

export default SearchRuleInput
