const scientificToNum = (oriNum) => {
    let numberStr = '' + oriNum
    if (numberStr.includes('e')) {
        // it is a scientific number
        let strAfterE = numberStr.substring(numberStr.indexOf('e') + 1)
        if (strAfterE.includes('-')) {
            let digit = Number(strAfterE.substring(strAfterE.indexOf('-') + 1))
            return Number(numberStr)
                .toFixed(digit + 1)
                .toString()
        } else {
            return Number(numberStr).toString()
        }
    } else {
        // not a scientific number
        return Number(numberStr).toString()
    }
}

export default scientificToNum
