import React from 'react'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import { Box, Hidden } from '@material-ui/core'

const drawerWidth = 185

const useStyles = makeStyles(() => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
}))

export const list = (
    <List>
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem button>
                <ListItemText>首頁</ListItemText>
            </ListItem>
        </Link>
        <Link to="/recommend" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem button>
                <ListItemText>推薦設定估算上鏈費</ListItemText>
            </ListItem>
        </Link>
        <Link to="/manual" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem button>
                <ListItemText>手動輸入估算上鏈費</ListItemText>
            </ListItem>
        </Link>
    </List>
)

const Sidebar = () => {
    const classes = useStyles()

    return (
        <div>
            <Hidden smDown>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <Box mt={1}>
                        <div className={classes.drawerContainer}>{list}</div>
                    </Box>
                </Drawer>
            </Hidden>
        </div>
    )
}

export default Sidebar
