import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

import { getScheduleTimeTextWithTz } from '../../../function/ScheduleTimeConvert'
import ethConvert from '../../../function/EtherNumberConvert'

const usePagenationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}))
const useStyles = makeStyles(() => ({
    container: {
        boxShadow: 'none',
        maxHeight: 500,
    },
    textRed: {
        color: 'red',
    },
}))

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
    const classes = usePagenationStyles()
    const theme = useTheme()

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0)
    }

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1)
    }

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const tableHeaderText = [
    '日期',
    '排程時間',
    '實際上鏈時間',
    '設定的Gas Price上限',
    '實際花費Gas Price',
    '實際花費上鏈費',
]

const EstimatePriceResultDetailTable = ({ data }) => {
    const classes = useStyles()
    const dailyDetailList = data.estimateGasPriceDailyDetailResponseList

    // TablePagination
    const [pageNum, setPageNum] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const labelDisplayedRows = () => {
        const dataLength = dailyDetailList.length
        if (pageNum * pageSize + pageSize < dataLength) {
            return `${pageNum * pageSize + 1}-${pageNum * pageSize + pageSize} 筆(共${dataLength}筆)`
        } else if (dataLength === 0) {
            return `無資料`
        } else {
            return `${pageNum * pageSize + 1}-${dataLength} 筆(共${dataLength}筆)`
        }
    }

    const handleChangePage = (event, newPage) => {
        setPageNum(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10))
        setPageNum(0)
    }

    const renderDate = (year, month, day) => {
        return year + '/' + month + '/' + day
    }

    const renderClearanceTime = (timestamp) => {
        return moment(timestamp).format('hh:mm A')
    }

    return (
        <div>
            <Paper>
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableHeaderText.map((text, index) => {
                                    return (
                                        <TableCell key={index} align="left">
                                            {text}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(pageSize > 0
                                ? dailyDetailList.slice(pageNum * pageSize, pageNum * pageSize + pageSize)
                                : dailyDetailList
                            ).map((detail) => {
                                return (
                                    <TableRow key={detail.year + detail.month + detail.day}>
                                        <TableCell align="left">
                                            {renderDate(detail.year, detail.month, detail.day)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {getScheduleTimeTextWithTz(data.schedulerTime)}
                                        </TableCell>
                                        {detail.clearanceTime > 0 ? (
                                            <TableCell align="left">
                                                {renderClearanceTime(detail.clearanceTime)}
                                            </TableCell>
                                        ) : (
                                            <TableCell align="left" className={classes.textRed}>
                                                當天無法上鏈
                                            </TableCell>
                                        )}
                                        <TableCell align="left">
                                            {data.isEnableSavingMode ? data.gasLimitGwei + ' Gwei' : '未設定節費模式'}
                                        </TableCell>
                                        {detail.clearanceTime > 0 && detail.clearanceTimeGasPriceGwei !== -1 ? (
                                            <TableCell align="left">{detail.clearanceTimeGasPriceGwei} Gwei</TableCell>
                                        ) : (
                                            <TableCell align="left" className={classes.textRed}>
                                                當天無法上鏈
                                            </TableCell>
                                        )}
                                        {detail.clearanceTime > 0 && detail.clearanceFeeEth !== -1 ? (
                                            <TableCell align="left">
                                                {ethConvert(detail.clearanceFeeEth)} (ETH)
                                            </TableCell>
                                        ) : (
                                            <TableCell align="left" className={classes.textRed}>
                                                當天無法上鏈
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 30, 100, { label: 'All', value: -1 }]}
                    component="div"
                    colSpan={3}
                    count={dailyDetailList.length}
                    page={pageNum}
                    rowsPerPage={pageSize}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    labelRowsPerPage="每頁筆數:"
                    labelDisplayedRows={labelDisplayedRows}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
        </div>
    )
}

EstimatePriceResultDetailTable.propTypes = {
    data: PropTypes.object.isRequired,
}

export default EstimatePriceResultDetailTable
