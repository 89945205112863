const getUTCTimeZoneText = () => {
    let tzOffset = 0 - new Date().getTimezoneOffset() / 60
    return 'UTC' + (tzOffset >= 0 ? '+' : '') + tzOffset
}

const countDaysBetween = (fromTS, toTS) => {
    return Math.round((toTS - fromTS) / (1000 * 60 * 60 * 24)) + 1
}

module.exports = {
    getUTCTimeZoneText,
    countDaysBetween,
}
