import React from 'react'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Toolbar from '@material-ui/core/Toolbar'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Home from './page/Home'
import RecommendEstimatePricePage from './page/recommend/RecommendEstimatePricePage'
import ManualEstimatePricePage from './page/manual/ManualEstimatePricePage'

const theme = createMuiTheme({
    typography: {
        fontFamily: '"Noto Sans TC", sans-serif',
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    title: {
        color: 'white',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

const App = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Router>
                            <Navbar />
                            <Sidebar />
                            <div className={classes.content}>
                                <Toolbar />
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route exact path="/recommend">
                                    <RecommendEstimatePricePage />
                                </Route>
                                <Route exact path="/manual">
                                    <ManualEstimatePricePage />
                                </Route>
                            </div>
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default App
