import React from 'react'
import { Box, Typography, Link, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import externalLink from '../../img/externalLink.svg'

const useStyles = makeStyles(() => ({
    textWhite: {
        color: '#fff',
    },
    iconButton: {
        paddingTop: '8px',
        paddingLeft: '3px',
        paddingRight: '0px',
    },
}))

const ManualEstimateInstruction = () => {
    const classes = useStyles()

    return (
        <Box m={2}>
            <Typography className={classes.textWhite}>
                <li>本系統依據以太坊主鏈歷史資料進行SPO上鏈手續費之估算，以此讓客戶評估使用主鏈之成本是否可接受。</li>
                <li>
                    SPO可設定排程上鏈時間以及是否開啟節費模式，若開啟節費模式，SPO會在排程時間過後，低於所設定的「上鏈費上限」時才進行上鏈。若未設定節費模式，則SPO將以所設定排程時間後，最快可上鏈的費用進行上鏈。
                </li>
                <li>
                    費用估算結果將根據ETH（以太幣）數值呈現，並提供使用者查詢起始日期前一日及查詢當下之幣值與美金的匯率值作為參考。
                </li>
            </Typography>
        </Box>
    )
}

const RecommendEstimateInstruction = () => {
    const classes = useStyles()

    return (
        <Box m={2}>
            <Typography className={classes.textWhite}>
                <li>
                    本功能依據以太坊歷史資料，提供使用者最佳SPO上鏈條件設定值，並估算上鏈費用，以此讓客戶評估使用主鏈之成本是否可接受。
                </li>
                <li>使用者須選擇欲查詢的時間區間，系統將根據以太坊該時間區間的歷史資料進行估算。</li>
                <li>
                    SPO可設定排程上鏈時間以及是否開啟節費模式，若開啟節費模式，SPO會在排程時間過後，低於所設定的「上鏈費上限」時才進行上鏈。若未設定節費模式，則SPO將以所設定排程時間後，最快可上鏈的費用進行上鏈。
                </li>
                <li>本功能將以ITM SPO設定為節費模式估算，查詢結果亦包含節費模式能夠為客戶省下的費用。</li>
                <li>
                    費用估算結果將根據ETH（以太幣）數值呈現，並提供使用者查詢起始日期前一日及查詢當下之幣值與美金的匯率值作為參考。
                </li>
            </Typography>
        </Box>
    )
}

const GasPriceLimitInstruction = () => {
    const classes = useStyles()

    return (
        <Box m={2} pt={2} pb={2}>
            <Typography variant="body1" component="span">
                <li>
                    如果您想在交易上更快被礦工驗證打包的話，Gas Price
                    就需要調整越高越好，如果想要花費較少，您可以通過降低您支付每單位 Gas 的金額來實現。
                </li>
                <li>如果需求較急、期望能夠越快上鏈越好，可以將 Gas Price 設定較高，這樣您的交易就能夠先行驗證。</li>
                <li>
                    建議設定：100Gwei，並且觀察估算結果是否能夠每天上鏈，若無法上鏈天數過多，再提高Gas Price上限值。
                </li>
            </Typography>
            <Box mt={1}>
                <Box>
                    <Link
                        id="gas-price-article-link"
                        component="a"
                        href="https://zombit.info/%E5%88%B0%E5%BA%95%E4%BB%80%E9%BA%BC%E6%98%AFgas%E3%80%81gas-limit%E3%80%81gas-price%EF%BC%9F/"
                        rel="noopener"
                        target="_blank"
                        underline="hover"
                        color="primary"
                    >
                        【教學】到底什麼是Gas、Gas Price、Gas Limit？
                        <IconButton className={classes.iconButton}>
                            <img alt="externalLink" src={externalLink} height="15px" />
                        </IconButton>
                    </Link>
                </Box>
                <Box>
                    <Link
                        id="etherscan-gas-tracker-link"
                        component="a"
                        href="https://etherscan.io/gasTracker"
                        rel="noopener"
                        target="_blank"
                        underline="hover"
                        color="primary"
                    >
                        參考Etherscan估價
                        <IconButton className={classes.iconButton}>
                            <img alt="externalLink" src={externalLink} height="15px" />
                        </IconButton>
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}

const ManualResultInstruction = () => {
    return (
        <Box mr={2} pt={1} pb={1}>
            <Typography variant="body1" component="span">
                <ol>
                    <li>總上鏈費用：在使用者選擇的時間日期區間，成功上鏈總共花費的上鏈費用。</li>
                    <li>可上鏈天數：若開啟節費模式，根據設定的上鏈費上限，能夠在當天23:59前成功上鏈的天數有幾天。</li>
                    <li>平均每日上鏈費用：總花費/可上鏈天數。</li>
                    <li>
                        是否設定節費模式：輸入的查詢條件是否有開啟節費模式。若開啟節費模式，SPO會在排程時間過後，低於所設定的「上鏈費上限」時才進行上鏈。若未設定節費模式，則SPO將以所設定排程時間後，最快可上鏈的費用進行上鏈。
                    </li>
                </ol>
            </Typography>
        </Box>
    )
}

export default ManualEstimateInstruction
export { ManualEstimateInstruction, RecommendEstimateInstruction, GasPriceLimitInstruction, ManualResultInstruction }
