import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, Divider, Grid, IconButton } from '@material-ui/core'
import NextIcon from '../../img/next.svg'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    textWhite: {
        color: 'white',
    },
    link: {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            color: '#3F51B5',
        },
    },
    floatRight: {
        float: 'right',
    },
})

const Home = () => {
    const classes = useStyles()
    return (
        <div>
            <Typography className={classes.textWhite} variant="h5" gutterBottom>
                首頁
            </Typography>
            <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                    <Card>
                        <Box ml={4} mr={4} mt={4} mb={2}>
                            <Typography variant="h6">推薦估算上鏈費用</Typography>
                        </Box>
                        <Box ml={4} mr={4} mt={1} mb={4}>
                            <Typography variant="body1" color="textSecondary" component="p">
                                根據使用者所選時間區間內之以太坊區塊歷史資料
                                ，推薦使用者最佳上鏈設定，並以此設定估算上鏈花費。
                            </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box className={classes.floatRight}>
                            <Link to="/recommend" className={classes.link}>
                                <IconButton>
                                    <img alt="nextIcon" src={NextIcon} height="20" />
                                </IconButton>
                            </Link>
                        </Box>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Card>
                        <Box ml={4} mr={4} mt={4} mb={2}>
                            <Typography variant="h6">手動輸入估算上鏈費用</Typography>
                        </Box>
                        <Box ml={4} mr={4} mt={1} mb={4}>
                            <Typography variant="body1" color="textSecondary" component="p">
                                根據使用者所選時間區間內之以太坊區塊歷史資料以及使用者所輸入的上鏈條件，估算上鏈花費。
                            </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box className={classes.floatRight}>
                            <Link to="/manual" className={classes.link}>
                                <IconButton>
                                    <img alt="nextIcon" src={NextIcon} height="20" />
                                </IconButton>
                            </Link>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Home
