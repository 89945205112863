import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, Box, Button, CircularProgress, Divider } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { RecommendEstimateInstruction } from '../Instruction'
import RecommendSettingResult from './RecommendSettingResult'
import OverallEstimatePrice from './OverallEstimatePrice'
import estimateServerApi from '../../../action/EstimateServerApi'

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: 'grey',
    },
    textWhite: {
        color: '#fff',
    },
    textGrey: {
        color: 'grey',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    dateTimePicker: {
        backgroundColor: 'white',
        borderRadius: '0.35rem',
        '& .MuiInputBase-input': {
            padding: '12px 12px 10px',
            color: 'grey',
            width: '120px',
        },
    },
    button: {
        backgroundColor: '#4E8BDC',
        color: 'white',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const EstimatePrice = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const defaultMinTS = new Date('2020-01-01').getTime()
    const defaultMaxTS = new Date().getTime()
    const [minDate, setMinDate] = useState(moment(defaultMinTS))
    const [maxDate, setMaxDate] = useState(moment(defaultMaxTS).subtract(1, 'week'))
    const [fromDate, setFromDate] = useState(minDate)
    const [toDate, setToDate] = useState(maxDate)
    const [result, setResult] = useState()

    // price card
    const [historyPriceTimestamp, setHistoryPriceTimestamp] = useState(0)
    const [beforeStartDateEthUSDPrice, setBeforeStartDateEthUSDPrice] = useState(0.0)
    const [currentEthUSDPrice, setCurrentEthUSDPrice] = useState(0.0)

    // progress circle
    const [isServerCalculating, setIsServerCalculating] = useState(false)
    const [isGettingHistoryPrice, setIsGettingHistoryPrice] = useState(false)
    const [isGettingCurrentPrice, setIsGettingCurrentPrice] = useState(false)
    const [isProgressing, setIsProgressing] = useState(false)

    const onFromDateChange = (date) => {
        if (toDate < date) {
            enqueueSnackbar('結束時間不能早於起始時間', {
                variant: 'warning',
            })
        } else {
            setFromDate(date)
        }
    }

    const onToDateChange = (date) => {
        if (date < fromDate) {
            enqueueSnackbar('結束時間不能早於起始時間', {
                variant: 'warning',
            })
        } else {
            setToDate(date)
        }
    }

    const getHistoryEthUSDPrice = (searchTimestamp) => {
        setIsGettingHistoryPrice(true)
        const searchDate = moment(searchTimestamp).format('YYYY-MM-DD')
        estimateServerApi
            .get('/ether/history/usd/' + searchTimestamp)
            .then((res) => {
                if (res.data.status === 'ok') {
                    setBeforeStartDateEthUSDPrice(Number(res.data.price).toFixed(2))
                    setHistoryPriceTimestamp(res.data.timestamp)
                } else {
                    enqueueSnackbar('查詢' + searchDate + '幣價資料失敗', {
                        variant: 'error',
                    })
                }
                setIsGettingHistoryPrice(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢' + searchDate + '幣價資料失敗', {
                    variant: 'error',
                })
                setIsGettingHistoryPrice(false)
            })
    }

    const getCurrentEthUSDPrice = () => {
        setIsGettingCurrentPrice(true)
        estimateServerApi
            .get('/ether/current/usd')
            .then((res) => {
                if (res.data.status === 'ok') {
                    setCurrentEthUSDPrice(Number(res.data.price).toFixed(2))
                } else {
                    enqueueSnackbar('查詢目前幣價資料失敗', {
                        variant: 'error',
                    })
                }
                setIsGettingCurrentPrice(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢目前幣價資料失敗', {
                    variant: 'error',
                })
                setIsGettingCurrentPrice(false)
            })
    }

    const handleSubmitClick = () => {
        setIsServerCalculating(true)
        setIsProgressing(true)
        setResult()

        const fromTimestamp = new Date(moment(fromDate).format('YYYY-MM-DD')).getTime()
        const toTimestamp = new Date(moment(toDate).format('YYYY-MM-DD')).getTime() + 1000

        estimateServerApi
            .get('/gas-price/automatic/estimate/' + fromTimestamp + '/' + toTimestamp)
            .then((res) => {
                if (res.data.status === 'ok') {
                    getHistoryEthUSDPrice(moment(fromTimestamp).subtract(1, 'day'))
                    getCurrentEthUSDPrice()
                    setResult(res.data)
                } else {
                    enqueueSnackbar('查詢資料失敗，請稍後再試或詢問開發人員', {
                        variant: 'error',
                    })
                }
                setIsServerCalculating(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢資料失敗，請稍後再試或詢問開發人員', {
                    variant: 'error',
                })
                setIsServerCalculating(false)
            })
    }

    // initialize time range
    useEffect(() => {
        estimateServerApi
            .get('/data/duration')
            .then((res) => {
                if (res.data.status === 'ok') {
                    setMinDate(moment(res.data.fromTimestamp))
                    setMaxDate(moment(res.data.toTimestamp))
                    setToDate(moment(res.data.toTimestamp))
                    setFromDate(moment(res.data.toTimestamp).subtract(1, 'week'))
                } else {
                    enqueueSnackbar('查詢資料時間區間失敗，請稍後再試或詢問開發人員', {
                        variant: 'error',
                    })
                }
            })
            .catch(() => {
                enqueueSnackbar('查詢資料時間區間失敗，請稍後再試或詢問開發人員', {
                    variant: 'error',
                })
            })
    }, [])

    useEffect(() => {
        if (!isServerCalculating && !isGettingHistoryPrice && !isGettingCurrentPrice) {
            setIsProgressing(false)
        }
    }, [isServerCalculating, isGettingHistoryPrice, isGettingCurrentPrice])

    return (
        <>
            <Box mt={1}>
                <Typography className={classes.textWhite} variant="h5" gutterBottom>
                    推薦設定估算上鏈價格
                </Typography>
                <RecommendEstimateInstruction />
            </Box>
            <Box mt={2}>
                <Typography className={classes.textWhite} variant="h6" gutterBottom>
                    輸入查詢條件
                </Typography>
            </Box>
            <Box mb={2}>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                    <Box>
                        <Typography className={classes.textWhite} variant="body1">
                            起始日期
                        </Typography>
                        <KeyboardDatePicker
                            className={classes.dateTimePicker}
                            disableToolbar
                            variant="inline"
                            margin="none"
                            format="YYYY/MM/DD"
                            id="date-picker-start-date"
                            value={fromDate}
                            onChange={onFromDateChange}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </Box>
                    <Box ml={2}>
                        <Typography className={classes.textWhite} variant="body1">
                            結束日期
                        </Typography>
                        <KeyboardDatePicker
                            className={classes.dateTimePicker}
                            disableToolbar
                            variant="inline"
                            margin="none"
                            format="YYYY/MM/DD"
                            id="date-picker-end-date"
                            value={toDate}
                            onChange={onToDateChange}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </Box>
                    <Box ml={2}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitClick}
                        >
                            確認
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Divider className={classes.divider} />

            <Box mt={2} mb={1}>
                <Typography className={classes.textWhite} variant="h6">
                    查詢結果
                </Typography>
            </Box>
            {result ? (
                <>
                    <RecommendSettingResult
                        recommendSchedulerTime={result.recommendSchedulerTime}
                        recommendGasPriceLimit={result.recommendGasPriceLimit}
                    />
                    <OverallEstimatePrice
                        historyPriceTimestamp={historyPriceTimestamp}
                        beforeStartDateEthUSDPrice={beforeStartDateEthUSDPrice}
                        currentEthUSDPrice={currentEthUSDPrice}
                        totalClearanceFeeEth={Number(result.totalClearanceFeeEth).toFixed(10)}
                        clearanceDay={result.clearanceDay}
                        avgClearanceFeeEth={Number(result.avgClearanceFeeEth).toFixed(10)}
                        unlimitedClearanceFeeEth={Number(result.unlimitedClearanceFeeEth).toFixed(10)}
                        savedClearanceFeeEth={Number(result.savedClearanceFeeEth).toFixed(10)}
                        setIsGettingHistoryPrice={setIsGettingHistoryPrice}
                        setIsGettingCurrentPrice={setIsGettingCurrentPrice}
                    />
                </>
            ) : (
                <Typography className={classes.textGrey} variant="body1">
                    尚無可顯示結果
                </Typography>
            )}
            <Backdrop className={classes.backdrop} open={isProgressing}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default EstimatePrice
