import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import ITMLogo from '../img/ITM.svg'
import { list } from './Sidebar'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
        textTransform: 'none',
        color: 'white',
    },
    avatar: {
        marginLeft: 'auto',
        width: '35px',
        height: '35px',
    },
    corp: {
        color: '#858796',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}))

const Navbar = () => {
    const classes = useStyles()
    const [open, setopen] = useState(false)

    const handleDrawerOpen = () => {
        setopen(!open)
    }

    const handleDrawerClose = () => {
        setopen(false)
    }

    return (
        <>
            <AppBar style={{ background: '#3D3F4F' }} position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                            <MenuIcon />
                        </IconButton>
                        <SwipeableDrawer open={open} onClose={handleDrawerClose} onOpen={handleDrawerOpen}>
                            <Toolbar />
                            <Divider />
                            {list}
                        </SwipeableDrawer>
                    </Hidden>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button className={classes.logo}>
                            <img alt="itmLogo" src={ITMLogo} width="100" />
                            <Hidden xsDown>
                                <Box m={2}>
                                    <Typography variant="h5">Gas Price Estimation</Typography>
                                </Box>
                            </Hidden>
                        </Button>
                    </Link>
                    <Avatar className={classes.avatar}>
                        <img src={ITMLogo} alt="ITMAvatar" width="30" />
                    </Avatar>
                    <Box ml={1}>
                        <Typography variant="caption" className={classes.corp}>
                            ITM Corp.
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navbar
