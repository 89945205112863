import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    cardContent: {
        flexShrink: 0,
        overflow: 'auto',
        width: 'auto',
    },
    tableCell: {
        overflow: 'auto',
        width: 'auto',
        borderBottom: 'none',
    },
    tableRow: {
        borderBottomStyle: 'solid',
        borderBottomColor: '#E8E8E8',
        borderBottomWidth: 1,
    },
}))

const RecommendSettingPriceCard = ({
    totalClearanceFee,
    clearanceDay,
    avgClearanceFee,
    unlimitedClearanceFee,
    savedClearanceFee,
    unit,
}) => {
    const classes = useStyles()

    return (
        <Box mt={1}>
            <TableContainer component={Card} className={classes.cardContent}>
                <Table>
                    <TableBody>
                        <TableRow id="total-cl-price" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">總上鏈費用</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">
                                    {totalClearanceFee}({unit})
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow id="total-cl-day" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">上鏈天數</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">{clearanceDay}天</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow id="avg-cl-price" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">平均每日上鏈費用</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">
                                    {avgClearanceFee}({unit})
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow id="no-save-mode-price" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">未使用節費模式所需花費</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">
                                    {unlimitedClearanceFee}({unit})
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow id="save-price" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">節費模式省下費用</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body1">
                                    {savedClearanceFee}({unit})
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
RecommendSettingPriceCard.propTypes = {
    totalClearanceFee: PropTypes.number.isRequired,
    clearanceDay: PropTypes.number.isRequired,
    avgClearanceFee: PropTypes.number.isRequired,
    unlimitedClearanceFee: PropTypes.number.isRequired,
    savedClearanceFee: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
}
export default RecommendSettingPriceCard
