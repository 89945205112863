import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, Box, CircularProgress, Divider, IconButton, Modal } from '@material-ui/core'
import EstimatePriceResultOverview from './EstimatePriceResultOverview'
import EstimatePriceResultDetailTable from './EstimatePriceResultDetailTable'
import { ManualEstimateInstruction } from '../Instruction'
import SearchRuleInput from './SearchRuleInput'
import ManualResultInstructionModal from '../modal/ManualResultInstructionModal'
import InfoIcon from '../../../img/infoButton.svg'

import estimateServerApi from '../../../action/EstimateServerApi'
import { getUTCTimeZoneText, countDaysBetween } from '../../../function/DateTimeUtil'

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: 'grey',
    },
    textWhite: {
        color: '#fff',
    },
    textGrey: {
        color: 'grey',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    iconButton: {
        paddingTop: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '8px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const EstimatePrice = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [fromTimestamp, setFromTimestamp] = useState(0)
    const [toTimestamp, setToTimestamp] = useState(0)
    const [result, setResult] = useState(null)

    // price card
    const [historyPriceTimestamp, setHistoryPriceTimestamp] = useState(0)
    const [beforeStartDateEthUSDPrice, setBeforeStartDateEthUSDPrice] = useState(0.0)
    const [currentEthUSDPrice, setCurrentEthUSDPrice] = useState(0.0)

    // progress circle
    const [isServerCalculating, setIsServerCalculating] = useState(false)
    const [isGettingHistoryPrice, setIsGettingHistoryPrice] = useState(false)
    const [isGettingCurrentPrice, setIsGettingCurrentPrice] = useState(false)
    const [isProgressing, setIsProgressing] = useState(false)

    const onInfoModalOpen = () => {
        setInfoModalOpen(true)
    }

    const onInfoModalClose = () => {
        setInfoModalOpen(false)
    }

    const getHistoryEthUSDPrice = (searchTimestamp) => {
        setIsGettingHistoryPrice(true)

        const searchDate = moment(searchTimestamp).format('YYYY-MM-DD')
        estimateServerApi
            .get('/ether/history/usd/' + searchTimestamp)
            .then((res) => {
                if (res.data.status === 'ok') {
                    setBeforeStartDateEthUSDPrice(Number(res.data.price).toFixed(2))
                    setHistoryPriceTimestamp(res.data.timestamp)
                } else {
                    enqueueSnackbar('查詢' + searchDate + '幣價資料失敗', {
                        variant: 'error',
                    })
                }
                setIsGettingHistoryPrice(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢' + searchDate + '幣價資料失敗', {
                    variant: 'error',
                })
                setIsGettingHistoryPrice(false)
            })
    }

    const getCurrentEthUSDPrice = () => {
        setIsGettingCurrentPrice(true)
        estimateServerApi
            .get('/ether/current/usd')
            .then((res) => {
                if (res.data.status === 'ok') {
                    setCurrentEthUSDPrice(Number(res.data.price).toFixed(2))
                } else {
                    enqueueSnackbar('查詢目前幣價資料失敗', {
                        variant: 'error',
                    })
                }
                setIsGettingCurrentPrice(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢目前幣價資料失敗', {
                    variant: 'error',
                })
                setIsGettingCurrentPrice(false)
            })
    }

    const onRuleSubmit = (fromDate, toDate, selectScheduleTime, isEnableSavingMode, gasPriceLimit) => {
        setIsServerCalculating(true)
        setIsProgressing(true)
        setResult()

        const fromTimestamp = new Date(moment(fromDate).format('YYYY-MM-DD')).getTime()
        const toTimestamp = new Date(moment(toDate).format('YYYY-MM-DD')).getTime() + 1000

        // adjust schedule time with current timezone offset
        let tzOffset = new Date().getTimezoneOffset() / 60
        let schedulerTime = selectScheduleTime + tzOffset
        if (schedulerTime < 0) {
            schedulerTime = 24 + schedulerTime
        }

        estimateServerApi
            .post('/gas-price/estimate', {
                fromTimestamp: fromTimestamp,
                toTimestamp: toTimestamp,
                gasPriceLimit: gasPriceLimit,
                isEnableSavingMode: isEnableSavingMode,
                schedulerTime: schedulerTime,
            })
            .then((res) => {
                if (res.data.status === 'ok') {
                    getHistoryEthUSDPrice(moment(fromTimestamp).subtract(1, 'day').format('x'))
                    getCurrentEthUSDPrice()
                    setResult(res.data)
                    setFromTimestamp(fromTimestamp)
                    setToTimestamp(toTimestamp)
                } else {
                    enqueueSnackbar('查詢資料失敗，請稍後再試或詢問開發人員', {
                        variant: 'error',
                    })
                }
                setIsServerCalculating(false)
            })
            .catch(() => {
                enqueueSnackbar('查詢資料失敗，請稍後再試或詢問開發人員', {
                    variant: 'error',
                })
                setIsServerCalculating(false)
            })
    }

    useEffect(() => {
        if (!isServerCalculating && !isGettingHistoryPrice && !isGettingCurrentPrice) {
            setIsProgressing(false)
        }
    }, [isServerCalculating, isGettingHistoryPrice, isGettingCurrentPrice])

    const renderTimeText = () => {
        return (
            '(依據以太坊主鏈' +
            moment(fromTimestamp).format('YYYY/MM/DD') +
            '至' +
            moment(toTimestamp).format('YYYY/MM/DD') +
            '之歷史資料估算)'
        )
    }

    return (
        <>
            <Box mt={1}>
                <Typography className={classes.textWhite} variant="h5" gutterBottom>
                    手動輸入估算上鏈費
                </Typography>
                <ManualEstimateInstruction />
            </Box>
            <Divider className={classes.divider} />
            <Box mt={2}>
                <Typography className={classes.textWhite} variant="h6" gutterBottom>
                    輸入查詢條件(目前時區:{getUTCTimeZoneText()})
                </Typography>
                <SearchRuleInput onRuleSubmit={onRuleSubmit} />
            </Box>
            <Divider className={classes.divider} />
            <Box mt={2} mb={2}>
                <Box mb={1} display="flex" flexDirection="row">
                    <Typography className={classes.textWhite} variant="h6" gutterBottom>
                        查詢結果{result && renderTimeText()}
                    </Typography>
                    {result && (
                        <IconButton className={classes.iconButton} onClick={onInfoModalOpen}>
                            <img alt="InfoIcon" src={InfoIcon} height="20px" />
                        </IconButton>
                    )}
                </Box>
                <Modal open={infoModalOpen} onClose={onInfoModalClose}>
                    <Box>
                        <ManualResultInstructionModal close={onInfoModalClose} />
                    </Box>
                </Modal>
                {result ? (
                    <EstimatePriceResultOverview
                        historyPriceTimestamp={historyPriceTimestamp}
                        beforeStartDateEthUSDPrice={beforeStartDateEthUSDPrice}
                        currentEthUSDPrice={currentEthUSDPrice}
                        totalClearanceFeeEth={Number(result.totalClearanceFeeEth).toFixed(10)}
                        searchDays={countDaysBetween(fromTimestamp, toTimestamp)}
                        canClearanceDay={result.canClearanceDay}
                        avgClearanceFeeEth={Number(result.avgClearanceFeeEth).toFixed(10)}
                        isEnableSavingMode={result.isEnableSavingMode}
                        savedClearanceFeeEth={Number(result.savedClearanceFeeEth).toFixed(10)}
                        setIsGettingHistoryPrice={setIsGettingHistoryPrice}
                        setIsGettingCurrentPrice={setIsGettingCurrentPrice}
                    />
                ) : (
                    <Typography className={classes.textGrey} variant="body1">
                        尚無可顯示結果
                    </Typography>
                )}
            </Box>
            <Divider className={classes.divider} />
            <Box mt={2}>
                <Box mb={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography className={classes.textWhite} variant="h6" gutterBottom>
                            每日上鏈細節{result && renderTimeText()}
                        </Typography>
                    </Box>
                    {result && (
                        <Typography className={classes.textWhite} variant="h6" gutterBottom>
                            目前時區: {getUTCTimeZoneText()}
                        </Typography>
                    )}
                    {result ? (
                        <EstimatePriceResultDetailTable data={result} />
                    ) : (
                        <Typography className={classes.textGrey} variant="body1">
                            尚無可顯示結果
                        </Typography>
                    )}
                </Box>
            </Box>
            <Backdrop className={classes.backdrop} open={isProgressing}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default EstimatePrice
