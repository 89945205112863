import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Paper, Typography } from '@material-ui/core'

import { ManualResultInstruction } from '../Instruction'

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: '#3D3F4F',
        position: 'absolute',
        width: 500,
        height: 'auto',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    textWhite: {
        color: 'white',
    },
    button: {
        backgroundColor: '#4E8BDC',
        color: 'white',
    },
}))

const getModalStyle = () => {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const ManualResultInstructionModal = ({ close }) => {
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle)

    return (
        <div style={modalStyle} className={classes.modal}>
            <Box mb={2}>
                <Typography className={classes.textWhite} variant="h6" component="h2">
                    欄位說明
                </Typography>
            </Box>
            <Paper>
                <ManualResultInstruction />
            </Paper>

            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button id="modal-close" className={classes.button} variant="contained" color="primary" onClick={close}>
                    關閉
                </Button>
            </Box>
        </div>
    )
}

ManualResultInstructionModal.propTypes = {
    close: PropTypes.func.isRequired,
}

export default ManualResultInstructionModal
